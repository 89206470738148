<template>
    <div class="outer">
        <header>
            <el-radio-group v-model="view" size="mini">
                <el-radio-button label="all">
                    All
                </el-radio-button>
                <el-radio-button label="planning">
                    Planning
                </el-radio-button>
                <el-radio-button label="pending_by_date">
                    Pending
                </el-radio-button>
                <el-radio-button label="paid">
                    Paid
                </el-radio-button>
            </el-radio-group>
        </header>

        <loader v-if="loading" />
        <div v-else class="inner">
            <payment-plans-by-project
                v-if="view !== 'pending_by_date'"
                :plan_state="view"
            />

            <template v-if="view === 'pending_by_date'">
                <div
                    v-for="invoiceGroup in pendingPaymentPlanInvoicesByDate"
                    :key="invoiceGroup.label"
                    class="invoice-date-group"
                    :class="{due: invoiceGroup.due}"
                >
                    <div class="group-label">
                        {{ invoiceGroup.label }}
                    </div>
                    <div class="payment-plan-invoices">
                        <payment-plan-invoice-block
                            v-for="invoice in invoiceGroup.invoices"
                            :key="invoice.id"
                            :invoice="invoice"
                            :project_ref="invoice.projectRef"
                            :estimate="invoice.estimate"
                            :invoices_count="invoice.planQuantity"
                            :consecutive="false"
                            @click="handleClickPaymentPlanInvoice"
                        />
                    </div>
                </div>

                <div class="invoice-unscheduled-group">
                    <div class="group-label">
                        Unscheduled
                    </div>
                    <div class="payment-plan-invoices">
                        <payment-plan-invoice-block
                            v-for="invoice in pendingPaymentPlansUnscheduled"
                            :key="invoice.id"
                            :invoice="invoice"
                            :project_ref="invoice.projectRef"
                            :estimate="invoice.estimate"
                            :invoices_count="invoice.planQuantity"
                            :consecutive="false"
                            @click="handleClickPaymentPlanInvoice"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import PaymentPlanInvoiceBlock from '@/components/blocks/PaymentPlanInvoiceBlock';

import Loader from '@/components/generic/Loader';
import PaymentPlansByProject from './components/PaymentPlansByProject';

import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';
import {ESTIMATE_STATUS} from '@/enums';

import {momentWithTz} from '@/utils';
import {fireRef2id} from '@/filters';

export default {
    name: 'payment-plans',
    components: {
        PaymentPlanInvoiceBlock,
        Loader,
        PaymentPlansByProject,
    },
    mixins: [ReferenceTemplateMixin],
    data() {
        return {
            search: '',
            loading: true,
            payment_plan_invoices: [],
            view: 'all',
        };
    },
    computed: {
        projects() {
            return this.$store.getters.projects;
        },
        payment_plans() {
            return this.$store.getters.paymentPlans;
        },
        pendingPaymentPlanInvoicesByDate() {
            // get scheduled invoices grouped by date
            return this.payment_plan_invoices
                .filter((ppi) => ppi.schedule_date && !ppi.invoice_id)
                .sort((a, b) => {
                    const aDate = this.$options.filters.timestamp2moment(
                        a.schedule_date
                    );
                    const bDate = this.$options.filters.timestamp2moment(
                        b.schedule_date
                    );
                    return aDate.diff(bDate);
                })
                .reduce((dates, ppi) => {
                    // get project & payment plan for this invoice
                    const plan = this.payment_plans.find(
                        (pp) =>
                            this.$options.filters.fireRef2id(
                                ppi.payment_plan
                            ) === pp.id
                    );
                    const project = this.projects.find(
                        (p) =>
                            this.$options.filters.fireRef2id(ppi.project) ===
                            p.id
                    );
                    if (!project) return;
                    const estimate = this.$store.getters.estimateWithId(
                        ppi.estimate
                    );
                    // if search is set, skip if none of project, plan or invoice matches condition
                    if (this.search) {
                        const reference = this.processTemplate(
                            ppi.reference_template || ppi.reference,
                            ppi.schedule_date,
                            ppi.sequence,
                            plan.quantity,
                            project.ref
                        ).toLowerCase();

                        const search = this.search.toLowerCase();
                        if (
                            !(
                                project.name.toLowerCase().includes(search) ||
                                project.ref.toLowerCase().includes(search) ||
                                plan.ref.toLowerCase().includes(search) ||
                                reference.includes(search)
                            )
                        ) {
                            return dates;
                        }
                    }

                    const now = momentWithTz().startOf('day');
                    let ppiDate = this.$options.filters.timestamp2moment(
                        ppi.schedule_date
                    );
                    let dateLabel = ppiDate.format('DD/MM/YYYY');
                    let range = 'day';

                    // group invoices by appropriate date range
                    if (ppiDate.diff(now, 'day') <= 0) {
                        dateLabel = 'Due now';
                        ppiDate = now;
                    } else if (ppiDate.diff(now, 'month') >= 6) {
                        dateLabel = 'Later';
                        ppiDate = momentWithTz(now)
                            .startOf('month')
                            .add(6, 'months');
                    } else {
                        if (ppiDate.diff(now, 'month') >= 2) {
                            range = 'month';
                            dateLabel = ppiDate.format('MMM YYYY');
                        } else if (ppiDate.diff(now, 'week') >= 2) {
                            range = 'week';
                            dateLabel = `W${ppiDate.format('WW YYYY')}`;
                        }
                        ppiDate.startOf(range);
                    }
                    let activeDate = dates[dates.length - 1];
                    if (
                        !activeDate ||
                        !activeDate.date.isSame(ppiDate, 'day')
                    ) {
                        activeDate = {
                            label: dateLabel,
                            date: ppiDate,
                            invoices: [],
                            due: now.isSame(ppiDate),
                        };
                        dates.push(activeDate);
                    }

                    // append payment plan details to invoice
                    let invoice = {...ppi, id: ppi.id};
                    invoice.planQuantity = plan.quantity;
                    invoice.projectRef = project.ref;
                    invoice.estimate = estimate;
                    invoice.total = ppi.line_items.reduce((sum, item) => {
                        return (sum +=
                            (item.amount *
                                (item.credit ? -1 : 1) *
                                (100 - (item.discount || 0))) /
                            100);
                    }, 0);

                    activeDate.invoices.push(invoice);
                    return dates;
                }, []);
        },
        pendingPaymentPlansUnscheduled() {
            return this.payment_plan_invoices
                .filter((ppi) => !ppi.schedule_date && !ppi.invoice_id)
                .sort((a, b) => {
                    // sort by payment plan ID then sequence
                    const aPlan = this.$options.filters.fireRef2id(
                        a.payment_plan
                    );
                    const bPlan = this.$options.filters.fireRef2id(
                        b.payment_plan
                    );
                    if (aPlan === bPlan) {
                        return a.sequence - b.sequence;
                    }
                    return aPlan.localeCompare(bPlan);
                })
                .reduce((invoices, ppi) => {
                    // get payment plan for this invoice
                    const plan = this.payment_plans.find(
                        (pp) =>
                            this.$options.filters.fireRef2id(
                                ppi.payment_plan
                            ) === pp.id
                    );

                    const project = this.projects.find(
                        (p) =>
                            this.$options.filters.fireRef2id(ppi.project) ===
                            p.id
                    );
                    if (!project) return invoices;
                    const estimate = this.$store.getters.estimateWithId(
                        ppi.estimate
                    );

                    // if search is set, skip if none of project or plan matches condition
                    // (unscheduled invoices don't have a reliably searchable reference)
                    if (this.search) {
                        const search = this.search.toLowerCase();
                        if (
                            !(
                                project.name.toLowerCase().includes(search) ||
                                project.ref.toLowerCase().includes(search) ||
                                plan.ref.toLowerCase().includes(search)
                            )
                        ) {
                            return invoices;
                        }
                    }

                    // append payment plan details to invoice
                    let invoice = {...ppi, id: ppi.id};
                    invoice.planQuantity = plan.quantity;
                    invoice.projectRef = project.ref;
                    invoice.estimate = estimate;
                    invoice.total = ppi.line_items.reduce((sum, item) => {
                        return (sum +=
                            (item.amount *
                                (item.credit ? -1 : 1) *
                                (100 - (item.discount || 0))) /
                            100);
                    }, 0);

                    invoices.push(invoice);
                    return invoices;
                }, []);
        },
    },
    async mounted() {
        await this.$bind(
            'payment_plan_invoices',
            this.$fire.collection('payment_plan_invoices'),
            {
                maxRefDepth: 0,
            }
        );

        this.loading = false;
    },
    methods: {
        fireRef2id,
        handleClickPaymentPlanInvoice(invoice, newWindow) {
            if (newWindow) {
                const project_id = fireRef2id(invoice.project);
                window.open(
                    `/project/${project_id}/paymentplan/invoice/${invoice.id}`,
                    'invoice',
                    'popup'
                );
            } else {
                this.$router.push({
                    name: 'project_detail_paymentplan_invoice',
                    params: {
                        project_id: this.$options.filters.fireRef2id(
                            invoice.project
                        ),
                        invoice_id: invoice.id,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
        box-sizing: border-box;
        min-height: 65px;
        padding: 10px 20px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        user-select: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .el-input {
            width: 200px;
        }

        .el-radio-group {
            margin-left: 10px;
        }
    }

    .inner {
        overflow: scroll;
        padding: 10px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.payment-plan,
.invoice-date-group,
.invoice-unscheduled-group {
    margin-top: 10px;
    padding: 10px;
    background-color: $grey;
    border-radius: 4px;

    &.due {
        background-color: rgba($red, 0.075);
    }

    .group-label {
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
    }
}

.invoice-unscheduled-group {
    background-color: rgba($orange, 0.075);
}

.project-estimates {
    margin-top: 10px;
    background-color: $grey;
    padding: 10px;
    border-radius: 4px;
    > * {
        margin-right: 10px;
    }
}
</style>
